"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.replace");

var _routers = _interopRequireDefault(require("@/router/routers"));

var _auth = require("@/utils/auth");

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("../store"));

var link = process.env.VUE_APP_BASE_API;

if (location.href.indexOf("https://") > -1) {
  link = process.env.VUE_APP_BASE_API.replace("http://", "https://");
}

var that = void 0; // 创建axios实例

var service = _axios.default.create({
  // baseURL: process.env.VUE_APP_BASE_API // api 的 base_url
  baseURL: link // api 的 base_url
  // baseURL: process.env.NODE_ENV === 'prod' ? process.env.VUE_APP_BASE_API : 'http://sit-acn-admin.dsicm.com/api/' // api 的 base_url
  // baseURL: process.env.NODE_ENV === 'prod' ? process.env.VUE_APP_BASE_API : 'http://192.168.120.50:8000/' // api 的 base_url
  // timeout: Config.timeout // 请求超时时间

}); // request拦截器


service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  Promise.reject(error);
}); // response 拦截器

service.interceptors.response.use(function (response) {
  var code = response.status;

  if (code < 200 || code > 300) {
    // Notification.error({
    //   title: response.message
    // })
    _elementUi.Message.error(response.message);

    return Promise.reject('error');
  } else {
    return response.data;
  }
}, function (error) {
  var code = 0;

  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      _elementUi.Notification.error({
        title: '网络请求超时',
        duration: 5000
      });

      return Promise.reject(error);
    }
  }

  if (code) {
    if (code === 401) {
      var _link = window.location.href;

      if (_link.indexOf("/index") != -1 || _link.indexOf("/feedback") != -1 || _link.indexOf("/introduction") != -1 || _link.indexOf("/busConsult") != -1 || _link.indexOf("/busConsultDetail") != -1 || _link.indexOf("/documentCenter") != -1 || _link.indexOf("/aboutUs") != -1 || _link.indexOf("/webService") != -1 || _link.indexOf("/webPrivacy") != -1 || _link.indexOf("/documentCenter") != -1) {
        _routers.default.push({
          path: '/login'
        });
      } else {
        var doms = document.getElementsByClassName('el-message-box')[0];
        console.log(doms, "doms");

        if (!doms) {
          console.log("进来了");

          _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning',
            showClose: false,
            showCancelButton: false
          }).then(function () {
            console.log("jinlaile");

            _store.default.dispatch('LogOut').then(function () {
              // location.reload() // 为了重新实例化vue-router对象 避免bug
              _routers.default.push({
                path: '/login'
              });
            }); // document.getElementsByClassName('el-message-box')[0].remove();


            document.getElementsByClassName('el-message-box__wrapper')[0].remove();
          });
        }
      }
    } else if (code === 403) {
      _routers.default.push({
        path: '/401'
      });
    } else if (code === 405) {
      _elementUi.Notification.error({
        title: '没有权限操作',
        duration: 2000
      });
    } else {
      console.log(error.response.data);
      var errorMsg = error.response.data.message;

      if (errorMsg !== undefined) {
        // Notification.error({
        //   title: errorMsg,
        //   duration: 5000
        // })
        console.log(errorMsg);

        _elementUi.Message.error(errorMsg);
      }
    }
  } else {
    _elementUi.Notification.error({
      title: '接口请求失败',
      duration: 5000
    });
  }

  return Promise.reject(error);
});
var _default = service;
exports.default = _default;