"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

var _Doc = _interopRequireDefault(require("@/components/Doc"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

var _SizeSelect = _interopRequireDefault(require("@/components/SizeSelect"));

var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));

var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default,
    SizeSelect: _SizeSelect.default,
    Search: _HeaderSearch.default,
    Doc: _Doc.default
  },
  data: function data() {
    return {
      Avatar: _avatar.default,
      dialogVisible: false
    };
  },
  created: function created() {},
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar", "device", "user", "baseApi"])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val
        });
      }
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    open: function open() {
      this.dialogVisible = true;
    },
    logout: function logout() {
      var _this = this;

      this.dialogVisible = false;
      this.$store.dispatch("LogOut").then(function () {
        _this.$router.push({
          path: "/login"
        });
      });
    }
  }
};
exports.default = _default;