"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserInfo = exports.logOut = exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _login = require("@/api/login");

var _auth = require("@/utils/auth");

var user = {
  state: {
    token: (0, _auth.getToken)(),
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        (0, _login.login)(userInfo.username, userInfo.password).then(function (res) {
          (0, _auth.setToken)(res.data.token, rememberMe);
          commit('SET_TOKEN', res.data.token);
          setUserInfo(res, commit); // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

          commit('SET_LOAD_MENUS', true);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    //验证码登陆
    LoginCode: function LoginCode(_ref2, userInfo) {
      var commit = _ref2.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        (0, _login.loginCode)(userInfo.phone, userInfo.code, userInfo.wxOpenId, userInfo.avatarPath, userInfo.nickName).then(function (res) {
          (0, _auth.setToken)(res.data.token, rememberMe);
          commit('SET_TOKEN', res.data.token);
          setUserInfo(res, commit); // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

          commit('SET_LOAD_MENUS', true);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref3) {
      var commit = _ref3.commit;
      console.log('走起来');
      var jurisdiction = {
        personalSample: {
          //csv导入列表
          personalSampleAll: false,
          //数据权限（能否选择查看我的数据或者全部数据，涉及调用接口）
          personalSampleDel: false //删除权限（是否展示checkbox 与删除按钮）

        },
        csvImport: {
          //数据映射审核
          csvImportAll: false,
          csvImportDel: false
        },
        dsicmRequest: {
          //数据申请
          dsicmRequestAll: false,
          dsicmRequestDel: false
        },
        cohort: {
          //样本管理
          cohortAll: false,
          cohortDel: false
        },
        trailRequest: {
          //受试者招募
          trailRequestAll: false,
          trailRequestDel: false
        },
        cohortDataTransform: {
          //清洗任务
          cohortDataTransformAll: false,
          cohortDataTransformDel: false
        }
      };
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)().then(function (res) {
          setUserInfo(res, commit);
          res.data.user.roles.forEach(function (item) {
            switch (item) {
              case 'personalSample:all':
                return jurisdiction.personalSample.personalSampleAll = true;
                break;

              case 'personalSample:del':
                return jurisdiction.personalSample.personalSampleDel = true;
                break;

              case 'csvImport:all':
                return jurisdiction.csvImport.csvImportAll = true;
                break;

              case 'csvImport:del':
                return jurisdiction.csvImport.csvImportDel = true;
                break;

              case 'dsicmRequest:all':
                return jurisdiction.dsicmRequest.dsicmRequestAll = true;
                break;

              case 'dsicmRequest:del':
                return jurisdiction.dsicmRequest.dsicmRequestDel = true;
                break;

              case 'cohort:all':
                return jurisdiction.cohort.cohortAll = true;
                break;

              case 'cohort:del':
                return jurisdiction.cohort.cohortDel = true;
                break;

              case 'trailRequest:all':
                return jurisdiction.trailRequest.trailRequestAll = true;
                break;

              case 'trailRequest:del':
                return jurisdiction.trailRequest.trailRequestDel = true;
                break;

              case 'cohortDataTransform:all':
                return jurisdiction.cohortDataTransform.cohortDataTransformAll = true;
                break;

              case 'cohortDataTransform:del':
                return jurisdiction.cohortDataTransform.cohortDataTransformDel = true;
                break;
            }
          });
          localStorage.setItem('JURISDICTION', JSON.stringify(jurisdiction));
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)().then(function (res) {
          logOut(commit);
          resolve();
        }).catch(function (error) {
          logOut(commit);
          reject(error);
        });
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};

var logOut = function logOut(commit) {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  (0, _auth.removeToken)();
};

exports.logOut = logOut;

var setUserInfo = function setUserInfo(res, commit) {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.data.user.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
  } else {
    commit('SET_ROLES', res.data.user.roles);
  }

  commit('SET_USER', res.data.user.user);
};

exports.setUserInfo = setUserInfo;
var _default = user;
exports.default = _default;