"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getCode = getCode;
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.getToken = getToken;
exports.login = login;
exports.loginCode = loginCode;
exports.logout = logout;
exports.setLoginPassword = setLoginPassword;
exports.wxLogin = wxLogin;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(username, password, code, uuid) {
  return (0, _request.default)({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      code: code,
      uuid: uuid
    }
  });
}

function loginCode(phone, code, wxOpenId, avatarPath, nickName) {
  return (0, _request.default)({
    url: 'auth/smsCodeLogin',
    method: 'post',
    data: {
      phone: phone,
      code: code,
      wxOpenId: wxOpenId,
      avatarPath: avatarPath,
      nickName: nickName
    }
  });
}

function setLoginPassword(password) {
  return (0, _request.default)({
    url: 'auth/setLoginPassword',
    method: 'post',
    data: {
      password: password
    }
  });
}

function getInfo() {
  return (0, _request.default)({
    url: 'auth/info',
    method: 'get'
  });
}

function getCodeImg(uuid) {
  var params = {
    uuid: uuid
  };
  return (0, _request.default)({
    url: 'auth/code',
    method: 'get',
    params: params
  });
}

function getCode(params) {
  return (0, _request.default)({
    url: 'auth/sendSmsCode',
    method: 'get',
    params: params
  });
}

function logout() {
  return (0, _request.default)({
    url: 'auth/logout',
    method: 'delete'
  });
}

function wxLogin(params) {
  return (0, _request.default)({
    url: 'wx/user/wxLogin',
    method: 'get',
    params: params
  });
}

function getToken(params) {
  return (0, _request.default)({
    url: 'auth/getToken',
    method: 'get',
    params: params
  });
}

var _default = {
  getToken: getToken
};
exports.default = _default;