"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadMenus = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.match");

var _routers = _interopRequireDefault(require("./routers"));

var _store = _interopRequireDefault(require("@/store"));

var _settings = _interopRequireDefault(require("@/settings"));

var _nprogress = _interopRequireDefault(require("nprogress"));

require("nprogress/nprogress.css");

var _auth = require("@/utils/auth");

var _menu = require("@/api/system/menu");

var _permission = require("@/store/modules/permission");

// progress bar
// progress bar style
// getToken from cookie
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration


var whiteList = ['/login']; // no redirect whitelist

_routers.default.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + _settings.default.title;
  } // NProgress.start()


  if ((0, _auth.getToken)()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });

      _nprogress.default.done();
    } else if (to.path === '/index' || to.path == '/feedback' || to.path === '/introduction' || to.path === '/busConsult' || to.path === '/busConsultDetail' || to.path === '/documentCenter' || to.path === '/aboutUs' || to.path === '/webService' || to.path === '/webPrivacy' || to.path === '/documentCenter') {
      next();

      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetInfo').then(function (res) {
          // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function (err) {
          _store.default.dispatch('LogOut').then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        }); // 登录时未拉取 菜单，在此处拉取

      } else if (_store.default.getters.loadMenus) {
        // 修改成false，防止死循环
        _store.default.dispatch('updateLoadMenus').then(function (res) {});

        loadMenus(next, to);
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    console.log(to.path, "whiteList");

    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else if (to.path == '/wxLogin') {
      next(); // 跳转到注册页面      
    } else if (to.path === '/index' || to.path == '/feedback' || to.path === '/introduction' || to.path === '/busConsult' || to.path === '/busConsultDetail' || to.path === '/documentCenter' || to.path === '/aboutUs' || to.path === '/webService' || to.path === '/webPrivacy' || to.path === '/documentCenter') {
      next();
    } else if (to.path === '/' || to.path === '' || to.path === '/dashboard') {
      next({
        path: '/index'
      });
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      // next({ path: '/index' })

      _nprogress.default.done();
    }
  }
}); // router.onError((error) => {
//     const pattern = /Loading chunk (\d)+ failed/g;
//     console.log(error.message, "error");
//     const isChunkLoadFailed = error.message && error.message.match(pattern);
//     const targetPath = router.history.pending.fullPath;
//     if (isChunkLoadFailed) {
//         router.replace(targetPath);
//     }
// });


_routers.default.onError(function (error) {
  var jsPattern = /Loading chunk (\S)+ failed/g;
  var cssPattern = /Loading CSS chunk (\S)+ failed/g;
  var isChunkLoadFailed = error.message.match(jsPattern || cssPattern);
  var targetPath = _routers.default.history.pending.fullPath;

  if (isChunkLoadFailed) {
    localStorage.setItem('targetPath', targetPath);
    window.location.reload();
  }
});

_routers.default.onReady(function () {
  var targetPath = localStorage.getItem('targetPath');
  var tryReload = localStorage.getItem('tryReload');

  if (targetPath) {
    localStorage.removeItem('targetPath');

    if (!tryReload) {
      _routers.default.replace(targetPath);

      localStorage.setItem('tryReload', true);
    } else {
      localStorage.removeItem('tryReload');
    }
  }
});

var loadMenus = function loadMenus(next, to) {
  (0, _menu.buildMenus)().then(function (res) {
    var asyncRouter = (0, _permission.filterAsyncRouter)(res);
    asyncRouter.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });

    _store.default.dispatch('GenerateRoutes', asyncRouter).then(function () {
      // 存储路由
      _routers.default.addRoutes(asyncRouter); // 动态添加可访问路由表


      next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
        replace: true
      }));
    });
  });
};

exports.loadMenus = loadMenus;

_routers.default.afterEach(function () {
  _nprogress.default.done(); // finish progress bar

});