"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var tree = {
  state: {
    treeListObj: {}
  },
  mutations: {
    treeListObj: function treeListObj(state, tree) {
      state.treeListObj = tree;
    }
  },
  getters: {
    getWiFiList: function getWiFiList(state) {
      return state.treeListObj;
    }
  }
};
var _default = tree;
exports.default = _default;