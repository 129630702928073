"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _objectSpread2 = _interopRequireDefault(require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

require("D:\\project\\rws-client-h5\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("D:\\project\\rws-client-h5\\node_modules\\core-js\\modules\\es6.promise.js");

require("D:\\project\\rws-client-h5\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("D:\\project\\rws-client-h5\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

var _antDesignVue = _interopRequireDefault(require("ant-design-vue"));

var _mavonEditor = _interopRequireDefault(require("mavon-editor"));

require("mavon-editor/dist/css/index.css");

var _Dict = _interopRequireDefault(require("./components/Dict"));

var _Permission = _interopRequireDefault(require("./components/Permission"));

require("./assets/styles/element-variables.scss");

require("./assets/styles/index.scss");

require("ant-design-vue/dist/antd.css");

var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));

require("highlight.js/styles/atom-one-dark.css");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _routers = _interopRequireDefault(require("./router/routers"));

require("./assets/icons");

require("./router/index");

require("echarts-gl");

var _vueCropper = _interopRequireDefault(require("vue-cropper"));

var _echarts = _interopRequireDefault(require("echarts"));

require("swiper/dist/css/swiper.css");

//
// 数据字典
// 权限指令
// global css
// 代码高亮
// icon
// permission control
_vue.default.use(_vueCropper.default);

_vue.default.use(_vueHighlightjs.default);

_vue.default.use(_mavonEditor.default);

_vue.default.use(_Permission.default);

_vue.default.use(_Dict.default);

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size

});

_vue.default.use(_antDesignVue.default);

_vue.default.prototype.$echarts = _echarts.default;

_vue.default.prototype.$formatDate = function (time, rules) {
  if (Number(time * 1000) == 0) {
    return "--/--";
  }

  var date = new Date(Number(time * 1000));
  var year = date.getFullYear();
  var month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
  var hour = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
  var minutes = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
  var seconds = date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds();
  var d = String(rules).split(" ");

  if (d.length == 1) {
    // 只要年月日 / 时分秒
    if (d[0].includes(":")) {
      // 时分秒
      return "".concat(hour, ":").concat(minutes, ":").concat(seconds);
    } else {
      // 年月日
      var tip = d[0].includes("/") ? "/" : "-";
      return "".concat(year).concat(tip).concat(month).concat(tip).concat(day);
    }
  } else if (d[1] == 'hh-mm') {
    var _tip = d[0].includes("/") ? "/" : "-";

    return "".concat(year).concat(_tip).concat(month).concat(_tip).concat(day, " ").concat(hour, ":").concat(minutes);
  } else {
    // 年月日 时分秒
    var _tip2 = d[0].includes("/") ? "/" : "-";

    return "".concat(year).concat(_tip2).concat(month).concat(_tip2).concat(day, " ").concat(hour, ":").concat(minutes, ":").concat(seconds);
  }
};

_vue.default.prototype.$exportExcel = function (name, type, res) {
  var blob = new Blob([res], {
    type: 'application/vnd.ms-excel;charset=utf-8'
  });

  if ("download" in document.createElement("a")) {
    // 非IE下载
    var downloadElement = document.createElement("a");
    var href = window.URL.createObjectURL(blob); //创建下载的链接

    console.log(href);
    downloadElement.href = href;
    downloadElement.download = name + '.' + type; //下载后文件名

    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载

    document.body.removeChild(downloadElement); //下载完成移除元素

    window.URL.revokeObjectURL(href); //释放掉blob对象
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, name);
  }
};

_vue.default.prototype.strPhone = function (str) {
  var phone = str.substring(0, 3) + '****' + str.substring(str.length - 4, str.length);
  return phone;
};

_vue.default.prototype.permissionNew = function (value) {
  //方法里面判断权限
  if (value && value instanceof Array && value.length > 0) {
    var roles = _store.default.getters && _store.default.getters.roles;
    var permissionRoles = value;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });

    if (!hasPermission) {
      return false;
    }

    return true;
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
};

_vue.default.prototype.strName = function (str) {
  var name = '';
  name = str.substring(0, 1) + '**'; // if(str.length > 2){
  //   name  = str.substring(0,1) + '**'
  // }else{
  //   name  = str.substring(0,1) + '*'
  // }

  return name;
};

_vue.default.prototype.$setLoading = function (props, tipText) {
  if (typeof props === 'boolean') props = {
    spinning: props
  };
  if (Object.prototype.toString.call(props) !== '[object Object]') props = {};

  if (tipText) {
    this.$app.loadingProps = (0, _objectSpread2.default)({
      tip: tipText
    }, props);
  } else {
    this.$app.loadingProps = (0, _objectSpread2.default)({
      tip: '加载中...'
    }, props);
  }
};

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});