"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _index = _interopRequireDefault(require("../layout/index"));

_vue.default.use(_vueRouter.default);

var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/user/login'], resolve);
  },
  hidden: true
}, {
  path: '/index',
  meta: {
    title: '科研首页',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/index'], resolve);
  },
  hidden: true
}, {
  path: '/feedback',
  meta: {
    title: '意见反馈',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/feedback'], resolve);
  },
  hidden: true
}, {
  path: '/introduction',
  meta: {
    title: '科研功能介绍',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/introduction'], resolve);
  },
  hidden: true
}, {
  path: '/busConsult',
  meta: {
    title: '企业咨询',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/busConsult'], resolve);
  },
  hidden: true
}, {
  path: '/busConsultDetail',
  meta: {
    title: '文章详情',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/busConsultDetail'], resolve);
  },
  hidden: true
}, {
  path: '/documentCenter',
  meta: {
    title: '文档中心',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/documentCenter'], resolve);
  },
  hidden: true
}, {
  path: '/introduction',
  meta: {
    title: '科研功能介绍',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/introduction'], resolve);
  },
  hidden: true
}, {
  path: '/aboutUs',
  meta: {
    title: '关于我们',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/index/aboutUs'], resolve);
  },
  hidden: true
}, {
  path: '/webService',
  meta: {
    title: '网站服务条款'
  },
  component: function component(resolve) {
    return require(['@/views/index/webService'], resolve);
  },
  hidden: true
}, {
  path: '/webPrivacy',
  meta: {
    title: '隐私权政策'
  },
  component: function component(resolve) {
    return require(['@/views/index/webPrivacy'], resolve);
  },
  hidden: true
}, {
  path: '/wxLogin',
  meta: {
    title: '微信登陆',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/user/wxLogin'], resolve);
  },
  hidden: true
}, {
  path: '/setPassword',
  meta: {
    title: '设置密码',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/user/setPassword'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}, {
  path: '/role',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/role/index'], resolve);
    },
    name: 'role',
    meta: {
      title: 'role'
    }
  }]
}, {
  path: '/system',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'deptNew',
    component: function component(resolve) {
      return require(['@/views/system/deptNew/index'], resolve);
    },
    name: '部门配置',
    meta: {
      title: '部门配置'
    }
  } // {
  //     path: 'userGroups',
  //     component: (resolve) => require(['@/views/system/userGroups/index'], resolve),
  //     name: '用户组管理',
  //     meta: {
  //         title: '用户组管理'
  //     }
  // },
  ]
}, {
  path: '/dsicm',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'dsicmCase',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmCase/index'], resolve);
    },
    name: 'dsicm医生链',
    meta: {
      title: 'dsicm医生链'
    }
  }, {
    path: 'dsicmMqConcept',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmMqConcept/index'], resolve);
    },
    name: 'dsicm术语',
    meta: {
      title: 'dsicm术语'
    }
  }, {
    path: 'dsicmMqDataDistribution',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmMqDataDistribution/index'], resolve);
    },
    name: 'dsicm数据分配',
    meta: {
      title: 'dsicm数据分配'
    }
  }, {
    path: 'dsicmMqPatient',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmMqPatient/index'], resolve);
    },
    name: 'dsicm患者',
    meta: {
      title: 'dsicm患者'
    }
  }, {
    path: 'dsicmMqPatientApplication',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmMqPatientApplication/index'], resolve);
    },
    name: 'dsicm患者申请',
    meta: {
      title: 'dsicm患者申请'
    }
  }, {
    path: 'dsicmMqPatientUser',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmMqPatientUser/index'], resolve);
    },
    name: 'dsicm医生用户',
    meta: {
      title: 'dsicm医生用户'
    }
  }, {
    path: 'dsicmMqPatientUserPatientMapping',
    component: function component(resolve) {
      return require(['@/views/dsicm/dsicmMqPatientUserPatientMapping/index'], resolve);
    },
    name: 'dsicm医患关系',
    meta: {
      title: 'dsicm医患关系'
    }
  }]
}, {
  path: '/datacollection',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  name: '数据采集',
  meta: {
    title: '数据采集'
  },
  children: [{
    path: 'csvlead',
    component: function component(resolve) {
      return require(['@/views/datacollection/csvlead/list'], resolve);
    },
    name: 'csv导入列表',
    meta: {
      title: 'csv导入列表'
    }
  }, {
    path: 'csvleadDetail',
    component: function component(resolve) {
      return require(['@/views/datacollection/csvlead/detail'], resolve);
    },
    name: 'csv导入详情',
    meta: {
      title: 'csv导入详情',
      parentName: 'csv导入列表',
      parentPath: '/datacollection/csvlead'
    }
  }, {
    path: 'csvleadIntroduction',
    component: function component(resolve) {
      return require(['@/views/datacollection/csvlead/introduction'], resolve);
    },
    name: 'csv导入',
    meta: {
      title: 'csv导入',
      parentName: 'csv导入列表',
      parentPath: '/datacollection/csvlead'
    }
  }, {
    path: 'dataMap',
    component: function component(resolve) {
      return require(['@/views/datacollection/dataMap/dataMap'], resolve);
    },
    name: '数据映射审核',
    meta: {
      title: '数据映射审核'
    }
  }, {
    path: 'editDetail',
    component: function component(resolve) {
      return require(['@/views/datacollection/dataMap/editDetail'], resolve);
    },
    name: '人工审核',
    meta: {
      title: '人工审核',
      parentName: '数据映射审核',
      parentPath: '/datacollection/dataMap'
    }
  }, {
    path: 'addField',
    component: function component(resolve) {
      return require(['@/views/datacollection/dataMap/addField'], resolve);
    },
    name: '添加标准字段',
    meta: {
      title: '添加标准字段',
      parentName: '数据映射审核',
      parentPath: '/datacollection/dataMap',
      parent: {
        parentName: '人工审核',
        parentPath: '/datacollection/editDetail'
      }
    }
  }, {
    path: 'realdata',
    component: function component(resolve) {
      return require(['@/views/datacollection/realdata/realdata'], resolve);
    },
    name: '真实世界数据',
    meta: {
      title: '真实世界数据'
    }
  }, {
    path: 'realdataDetail',
    component: function component(resolve) {
      return require(['@/views/datacollection/realdata/realdataDetail'], resolve);
    },
    name: '数据详情',
    meta: {
      title: '数据详情',
      parentName: '真实世界数据',
      parentPath: '/datacollection/realdata'
    }
  }]
}, {
  path: '/datamanage',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  name: '数据管理',
  meta: {
    title: '数据管理'
  },
  children: [{
    path: 'recruitment',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/recruitment'], resolve);
    },
    name: '受试者招募',
    meta: {
      title: '受试者招募'
    }
  }, {
    path: 'editRecruitment',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/addRecruitment'], resolve);
    },
    name: '编辑受试组',
    meta: {
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment',
      title: '编辑受试组',
      parent: {
        parentName: '受试组详情',
        parentPath: '/datamanage/testGroupDetail'
      }
    }
  }, {
    path: 'addRecruitment',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/addRecruitment'], resolve);
    },
    name: '新建受试组',
    meta: {
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment',
      title: '新建受试组'
    }
  }, {
    path: 'recruitmentSetVariable',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/setVariable'], resolve);
    },
    name: '受试者设置变量',
    meta: {
      title: '设置变量',
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment'
    }
  }, {
    path: 'analysisResult',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/analysisResult'], resolve);
    },
    name: '申请结果',
    meta: {
      title: '申请结果',
      parentName: '受试组详情',
      parentPath: '/datamanage/testGroupDetail'
    }
  }, {
    path: 'sampleManagement',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/sampleManagement'], resolve);
    },
    name: '样本管理',
    meta: {
      title: '样本管理'
    }
  }, {
    path: 'addSample',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/addSample'], resolve);
    },
    name: '新建样本',
    meta: {
      title: '新建样本',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'editSample',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/addSample'], resolve);
    },
    name: '编辑样本',
    meta: {
      title: '编辑样本',
      parentName: '样本详情',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'viewSample',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/addSample'], resolve);
    },
    name: '编辑样本',
    meta: {
      title: '编辑样本',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement',
      parent: {
        parentName: '样本详情',
        parentPath: '/datamanage/sampleDetail'
      }
    }
  }, {
    path: 'seeSample',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/addSample'], resolve);
    },
    name: '查看样本',
    meta: {
      title: '查看样本',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'realdataDetailRecruitment',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/realdataDetail'], resolve);
    },
    name: '设置变量',
    meta: {
      title: '数据可视化',
      parentName: '样本管理',
      parentPath: '/datamanage/recruitment',
      parent: {
        parentName: '受试组详情',
        parentPath: '/datamanage/testGroupDetail'
      }
    }
  }, {
    path: 'sampleDetail',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/sampleDetail'], resolve);
    },
    name: '样本详情',
    meta: {
      title: '样本详情',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'sampleList',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/sampleList'], resolve);
    },
    name: '样本列表',
    meta: {
      title: '样本列表',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'sampCreateNewTask',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/createNewTask'], resolve);
    },
    name: '开始清洗-样本',
    meta: {
      title: '开始清洗-样本',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement',
      parent: {
        parentName: '清洗记录-样本',
        parentPath: '/datamanage/sampCleanRecord'
      }
    }
  }, {
    path: 'createNewTaskSuccessSample',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/createNewTaskSuccess'], resolve);
    },
    name: '创建样本清洗任务成功',
    meta: {
      title: '创建样本清洗任务成功',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement',
      parent: {
        parentName: '清洗记录-样本',
        parentPath: '/datamanage/sampCleanRecord'
      }
    }
  }, {
    path: 'sampCleanRecord',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/cleanRecord'], resolve);
    },
    name: '清洗记录-样本',
    meta: {
      title: '清洗记录-样本',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'testCreateNewTask',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/createNewTask'], resolve);
    },
    name: '开始清洗-受试者',
    meta: {
      title: '开始清洗-受试者',
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment',
      parent: {
        parentName: '清洗记录-受试者',
        parentPath: '/datamanage/testCleanRecord'
      }
    }
  }, {
    path: 'testCleanRecord',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/cleanRecord'], resolve);
    },
    name: '清洗记录-受试者',
    meta: {
      title: '清洗记录-受试者',
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment'
    }
  }, {
    path: 'testGroupDetail',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/testGroupDetail'], resolve);
    },
    name: '受试组详情',
    meta: {
      title: '受试组详情',
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment'
    }
  }, {
    path: 'recruitmentBasicFile',
    component: function component(resolve) {
      return require(['@/views/datamanage/recruitment/recruitmentBasicFile'], resolve);
    },
    name: '受试组详情-基本档案',
    meta: {
      title: '受试组详情-基本档案',
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment',
      parent: {
        parentName: '受试组详情',
        parentPath: '/datamanage/testGroupDetail'
      }
    }
  }, {
    path: 'sampleBasicFile',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/sampleBasicFile'], resolve);
    },
    name: '样本详情-基本档案',
    meta: {
      title: '样本详情-基本档案',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement',
      parent: {
        parentName: '样本详情',
        parentPath: '/datamanage/sampleDetail'
      }
    }
  }, {
    path: 'sampleSuccess',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/sampleSuccess'], resolve);
    },
    name: '申请已提交',
    meta: {
      title: '申请已提交',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement'
    }
  }, {
    path: 'realdataDetail',
    component: function component(resolve) {
      return require(['@/views/datamanage/sampleManagement/realdataDetail'], resolve);
    },
    name: '数据可视化',
    meta: {
      title: '数据可视化',
      parentName: '样本管理',
      parentPath: '/datamanage/sampleManagement',
      parent: {
        parentName: '样本详情',
        parentPath: '/datamanage/sampleDetail'
      }
    }
  }, {
    path: 'createNewTaskSuccessRecruitment',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/createNewTaskSuccess'], resolve);
    },
    name: '创建受试者清洗任务成功',
    meta: {
      title: '创建受试者清洗任务成功',
      parentName: '受试者招募',
      parentPath: '/datamanage/recruitment',
      parent: {
        parentName: '清洗记录-受试者',
        parentPath: '/datamanage/testCleanRecord'
      }
    }
  }]
}, {
  path: '/cleanTask',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  name: '清洗任务',
  meta: {
    title: '清洗任务'
  },
  children: [{
    path: 'cleanTaskLoad',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/cleanTaskLoad'], resolve);
    },
    name: '上传文件',
    meta: {
      title: '上传文件',
      parentName: '清洗任务列表',
      parentPath: '/cleanTask/cleanTaskList'
    }
  }, {
    path: 'cleanTaskDetail',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/cleanTaskDetail'], resolve);
    },
    name: '详情日志',
    meta: {
      title: '详情日志',
      parentName: '清洗任务列表',
      parentPath: '/cleanTask/cleanTaskList'
    }
  }, {
    path: 'cleanTaskList',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/cleanTaskList'], resolve);
    },
    name: '清洗任务列表',
    meta: {
      title: '清洗任务列表'
    }
  }, {
    path: 'createNewTask',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/createNewTask'], resolve);
    },
    name: '创建清洗任务',
    meta: {
      title: '创建清洗任务',
      parentName: '清洗任务列表',
      parentPath: '/cleanTask/cleanTaskList',
      parent: {
        parentName: '清洗记录',
        parentPath: '/cleanTask/cleanRecord'
      }
    }
  }, {
    path: 'cleanRecord',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/cleanRecord'], resolve);
    },
    name: '清洗记录',
    meta: {
      title: '清洗记录',
      parentName: '清洗任务列表',
      parentPath: '/cleanTask/cleanTaskList'
    }
  }, {
    path: 'createNewTaskSuccess',
    component: function component(resolve) {
      return require(['@/views/datacollection/cleanTask/createNewTaskSuccess'], resolve);
    },
    name: '创建清洗任务成功',
    meta: {
      title: '创建清洗任务成功',
      parentName: '清洗任务列表',
      parentPath: '/cleanTask/cleanTaskList'
    }
  }]
}, {
  path: '/businessManagement',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  name: '业务管理',
  meta: {
    title: '业务管理'
  },
  children: [{
    path: 'conceptManage',
    component: function component(resolve) {
      return require(['@/views/businessManagement/concept/concept'], resolve);
    },
    name: '术语管理',
    meta: {
      title: '术语管理' // parentName: '清洗任务列表',
      // parentPath: '/cleanTask/cleanTaskList',

    }
  }, {
    path: 'userGroupManage',
    component: function component(resolve) {
      return require(['@/views/businessManagement/concept/userGroup'], resolve);
    },
    name: '用户组管理',
    meta: {
      title: '用户组管理'
    }
  }]
}]; // import csvlead from "@/api/datacollection/csvlead";
// import realdataDetail from "@/api/datamanage/realdataDetail";

exports.constantRouterMap = constantRouterMap;

var _default = new _vueRouter.default({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});

exports.default = _default;