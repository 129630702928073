"use strict";

var _interopRequireDefault = require("D:/project/rws-client-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.string.starts-with");

var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }

      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{
          path: "/dashboard",
          meta: {
            title: "首页"
          }
        }].concat(matched);
      }

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });

      if (this.levelList[this.levelList.length - 1].meta.parentName) {
        var level = {
          name: this.levelList[this.levelList.length - 1].meta.parentName,
          path: this.levelList[this.levelList.length - 1].meta.parentPath,
          title: this.levelList[this.levelList.length - 1].meta.parentName,
          meta: {
            title: this.levelList[this.levelList.length - 1].meta.parentName
          },
          parentName: this.levelList[this.levelList.length - 1].meta.parentName,
          query: this.$route.query
        };
        this.levelList.splice(this.levelList.length - 1, 0, level);
      }

      if (this.levelList[this.levelList.length - 1].meta.parent) {
        var _level = {
          name: this.levelList[this.levelList.length - 1].meta.parent.parentName,
          path: this.levelList[this.levelList.length - 1].meta.parent.parentPath,
          title: this.levelList[this.levelList.length - 1].meta.parent.parentName,
          meta: {
            title: this.levelList[this.levelList.length - 1].meta.parent.parentName
          },
          parentName: this.levelList[this.levelList.length - 1].meta.parent.parentName,
          query: this.$route.query
        };
        this.levelList.splice(this.levelList.length - 1, 0, _level);
      }
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;

      if (!name) {
        return false;
      }

      return name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      var params = this.$route.params;

      var toPath = _pathToRegexp.default.compile(path);

      return toPath(params);
    },
    handleLinks: function handleLinks(item) {
      var redirect = item.redirect,
          path = item.path; //   this.$router.push(this.pathCompile("csvlead"));
      //   if (redirect) {
      //     console.log("ss");
      //     this.$router.push(redirect);
      //     return;
      //   }
      //   if (item.parentName) {
      //     console.log(item.title, "path");
      //     this.$router.push({
      //       name: item.title,
      //     });
      //     return;
      //   }
      //   this.$router.push(this.pathCompile(path));
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;
      console.log(item, "aa"); //   return false;

      if (redirect) {
        this.$router.push(redirect);
        return;
      } //   this.$router.push(this.pathCompile(path));


      this.$router.push({
        path: item.path,
        query: item.query
      });
    }
  }
};
exports.default = _default;